import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-es6",
  "date": "2015-09-13",
  "title": "REACT & ES6",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "ES6 is here and now. So writing React JS components in ES6 will be beneficial considering the new features ES6 brings to us."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Let's look at some of these new features:`}</p>
    <ul>
      <li parentName="ul">{`Classes`}</li>
      <li parentName="ul">{`Enhanced Object Literals`}</li>
      <li parentName="ul">{`Block-scoped binding constructs (let + const)`}</li>
      <li parentName="ul">{`Property Initializers`}</li>
      <li parentName="ul">{`Arrow Functions`}</li>
      <li parentName="ul">{`Template Strings`}</li>
      <li parentName="ul">{`Spread Attributes`}</li>
      <li parentName="ul">{`Desconstructing Attributes`}</li>
      <li parentName="ul">{`Generators`}</li>
      <li parentName="ul">{`Data Structures (Map, Set, WeakMap, WeakSet)`}</li>
      <li parentName="ul">{`...and many more`}</li>
    </ul>
    <h2>{`Transpilers`}</h2>
    <p>{`We can use ES6 with `}<inlineCode parentName="p">{`transpilers`}</inlineCode>{` to convert the source code into ES5, so that most of the browsers can still execute JavaScript source code.`}</p>
    <p>{`Here are few popular transpilers:`}</p>
    <ul>
      <li parentName="ul">{`Traceur`}</li>
      <li parentName="ul">{`JSTransform (depreciated)`}</li>
      <li parentName="ul">{`Babel`}</li>
    </ul>
    <h2>{`Babel`}</h2>
    <p>{`Babel was created by Sebastian McKenzie at the fall of 2014.`}</p>
    <p>{`Babel supports JSX, ES6 and ES7. It is widely used including `}<strong parentName="p">{`Facebook`}</strong>{`.`}</p>
    <h2>{`Classes`}</h2>
    <p>{`ES6 classes provide you with a concise way of writing React components:`}</p>
    <p>{`Instead of using the `}<strong parentName="p">{`React.createClass`}</strong>{` method to define a component, we can define a bonafide ES6 class that extends `}<strong parentName="p">{`React.Component`}</strong></p>
    <p><strong parentName="p">{`ES5`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Photo = React.createClass({
  handleDoubleTap: function(e) { … },
  render: function() { … },
});
`}</code></pre>
    <p><strong parentName="p">{`ES6`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Photo extends React.Component {
  handleDoubleTap(e) { … }
  render() { … }
}
`}</code></pre>
    <h2>{`Property Initializers`}</h2>
    <p>{`In ES6 property types and defaults are static properties of its class.`}</p>
    <p>{`Component's initial state can be defined using ES6.`}</p>
    <p><strong parentName="p">{`ES5`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Video = React.createClass({
  getDefaultProps: function() {
    return {
      autoPlay: false,
      maxLoops: 10,
    };
  },
  getInitialState: function() {
    return {
      loopsRemaining: this.props.maxLoops,
    };
  },
  propTypes: {
    autoPlay: React.PropTypes.bool.isRequired,
    maxLoops: React.PropTypes.number.isRequired,
    posterFrameSrc: React.PropTypes.string.isRequired,
    videoSrc: React.PropTypes.string.isRequired,
  },
});
`}</code></pre>
    <p><strong parentName="p">{`ES6`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Video extends React.Component {
  static defaultProps = {
    autoPlay: false,
    maxLoops: 10,
  }
  static propTypes = {
    autoPlay: React.PropTypes.bool.isRequired,
    maxLoops: React.PropTypes.number.isRequired,
    posterFrameSrc: React.PropTypes.string.isRequired,
    videoSrc: React.PropTypes.string.isRequired,
  }
  state = {
    loopsRemaining: this.props.maxLoops,
  }
}
`}</code></pre>
    <h2>{`Arrow Functions`}</h2>
    <p>{`The `}<strong parentName="p">{`React.createClass`}</strong>{` method used to perform some extra binding work on your component's instance methods to make sure that, inside them, the `}<strong parentName="p">{`this`}</strong>{` keyword would refer to the instance of the component in question.`}</p>
    <p><strong parentName="p">{`ES5`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Autobinding, brought to you by React.createClass
var PostInfo = React.createClass({
  handleOptionsButtonClick: function(e) {
    // Here, 'this' refers to the component instance.
    this.setState({showOptionsModal: true});
  },
});
`}</code></pre>
    <p>{`By combining two ES6 features – `}<inlineCode parentName="p">{`arrow functions`}</inlineCode>{` and `}<inlineCode parentName="p">{`property initializers`}</inlineCode>{` – opt-in binding to the component instance becomes a easier:`}</p>
    <p><strong parentName="p">{`ES6`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class PostInfo extends React.Component {
  handleOptionsButtonClick = (e) => {
    this.setState({showOptionsModal: true});
  }
}
`}</code></pre>
    <h2>{`Dynamic property names & template strings`}</h2>
    <p>{`One of the enhancements to object literals include the ability to assign to a derived property name. We might have originally done something like this to set a piece of state:`}</p>
    <p><strong parentName="p">{`ES5`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Form = React.createClass({
  onChange: function(inputName, e) {
    var stateToSet = {};
    stateToSet[inputName + 'Value'] = e.target.value;
    this.setState(stateToSet);
  },
});
`}</code></pre>
    <p>{`Now, we have the ability to construct objects whose property names are determined by a JavaScript expression at runtime. Here, we use a template string to determine which property to set on state:`}</p>
    <p><strong parentName="p">{`ES6`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Form extends React.Component {
  onChange(inputName, e) {
    this.setState({
      [\`\${inputName}Value\`]: e.target.value,
    });
  }
}
`}</code></pre>
    <h2>{`Destructuring & spread attributes`}</h2>
    <p>{`Often when composing components, we might want to pass down most of a parent component's props to a child component, but not all of them. In combining ES6+ `}<inlineCode parentName="p">{`destructuring`}</inlineCode>{` with JSX `}<inlineCode parentName="p">{`spread attributes`}</inlineCode>{`, this becomes easier:`}</p>
    <p><strong parentName="p">{`ES6`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class AutoloadingPostsGrid extends React.Component {
  render() {
    var {
      className,
      // contains all properties of this.props except for className
      ...others,
    } = this.props;
    return (
      <div className={className}>
        <PostsGrid {...others} />
        <button onClick={this.handleLoadMoreClick}>Load more</button>
      </div>
    );
  }
}
`}</code></pre>
    <p>{`We can combine JSX spread attributes with regular attributes too, taking advantage of a simple precedence rule to implement overrides and defaults. This element will acquire the className “override” even if there exists a `}<strong parentName="p">{`className`}</strong>{` property in `}<strong parentName="p">{`this.props`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div {...this.props} className="override">
  …
</div>
`}</code></pre>
    <p>{`This element will regularly have the `}<inlineCode parentName="p">{`className`}</inlineCode>{` “base” unless there exists a `}<inlineCode parentName="p">{`className`}</inlineCode>{` property in `}<strong parentName="p">{`this.props`}</strong>{` to override it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div className="base" {...this.props}>
  …
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      